<template>
  <div>
    <AppTable
      :rows="invites.value"
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: invites.limit,
        count: invites.count,
        page: invites.page,
      }"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>
          <b-col md="4" xl="4" class="mb-1">
            <b-form-group>
              <label for="datepicker-placeholder">Resident Location</label>

              <v-select
                v-model="countryOfResidenceId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="countries"
                :reduce="option => option.id"
                @change="changeResidentLocation"
              />
            </b-form-group>
          </b-col>

          <b-col md="4" xl="4" class="mb-1">
            <b-form-group>
              <label for="datepicker-placeholder">Current Location</label>

              <v-select
                v-model="currentLocationId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="countries"
                :reduce="option => option.id"
                @change="changeCurrentLocation"
              />
            </b-form-group>
          </b-col>

          <b-col md="4" xl="4" class="mb-1">
            <b-form-group>
              <label for="datepicker-placeholder">User type</label>

              <v-select
                v-model="userType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="userTypes"
                :reduce="option => option.key"
                @change="changeUserType"
              />
            </b-form-group>
          </b-col>

          <b-col md="4" xl="4" class="mb-1">
            <b-form-group>
              <label for="datepicker-placeholder">User status</label>

              <v-select
                v-model="status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :reduce="option => option.key"
                :options="statusTypes"
                @change="changeUserStatus"
              />
            </b-form-group>
          </b-col>

          <b-col md="1" xl="1" class="mb-1 mt-auto pb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon icon="SearchIcon" size="18" />
            </b-button>
          </b-col>
        </b-row>
      </template>

      <template #default="{ column, row, formattedRow }">
        <!-- Column: Action -->
        <span v-if="column.field === 'action'" class="d-flex">
          <b-button
            class="mt-auto text-white"
            type="button"
            variant="success"
            @click="approve(row)"
            :disabled="row.waitingList.status === 'approved'"
          >
            <span class="text-white"> Approve </span>
          </b-button>

          <b-button
            class="mt-auto text-white ml-1"
            type="button"
            variant="info"
            @click="decline(row)"
            :disabled="row.waitingList.status === 'declined'"
          >
            <span class="text-white"> Decline </span>
          </b-button>
        </span>

        <span v-else-if="column.field === 'countryId' && row.user && row.user.countryId">
          {{ row.user && country(row.user.countryId) }}
        </span>

        <span v-else-if="column.field === 'countryOfResidenceId' && row.countryOfResidenceId">
          {{ row.countryOfResidenceId && country(row.countryOfResidenceId) }}
        </span>

        <b-badge v-else-if="column.field === 'userType' && row.userType" variant="primary" class="text-white">
          {{ row.userType.replaceAll('_', ' ') }}
        </b-badge>

        <span v-else-if="column.field === 'comment'" class="comments">
          {{ row.comment }}
        </span>

        <!-- default render cell -->
        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BButton, BRow, BCol, BFormGroup, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppTable from '@/components/AppTable.vue'
import store from '@/store/index'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

import { columns } from './config/tableConfig'
import { userTypes, statusTypes } from './config/types'

export default {
  components: {
    AppTable,
    BBadge,
    vSelect,
    BButton,
    BRow,
    BCol,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      columns,
      currentLocationId: null,
      countryOfResidenceId: null,
      userType: null,
      status: statusTypes.pending,
      tableKey: Math.random(),
    }
  },

  computed: {
    ...mapGetters({
      invites: 'userInvites/invites',
      countries: 'countries/countries',
    }),

    direction() {
      return store.state.appConfig.isRTL
    },

    userTypes() {
      return Object.entries(userTypes).map(([key, value], index) => {
        return { id: index + 1, name: value.replaceAll('_', ' '), key }
      })
    },

    statusTypes() {
      return Object.entries(statusTypes).map(([key, value], index) => {
        return { id: index + 1, name: value.replaceAll('_', ' '), key }
      })
    },
  },
  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchCountries: 'countries/fetchCountries',
      fetchUserInvites: 'userInvites/fetchUserInvites',
      updateUserStatus: 'userInvites/updateUserStatus',
    }),

    initState() {
      const limit = this.selectPerPage
      const query = this.createQueryConfig({ limit })

      this.fetchInvites(query)
      this.fetchCountries()
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })

      return this.fetchInvites(query)
    },

    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })

      return this.fetchInvites(query)
    },

    changeResidentLocation(locationId) {
      const query = this.createQueryConfig({ countryOfResidenceId: locationId })

      return this.fetchInvites(query)
    },

    changeCurrentLocation(locationId) {
      const query = this.createQueryConfig({ currentLocationId: locationId })

      return this.fetchInvites(query)
    },

    changeUserType(userType) {
      const query = this.createQueryConfig({ userType })

      return this.fetchInvites(query)
    },

    changeUserStatus(status) {
      const query = this.createQueryConfig({ status })

      return this.fetchInvites(query)
    },

    createQueryConfig(config) {
      return {
        page: this.invites.page,
        limit: this.invites.limit,

        currentLocationId: this.currentLocationId || undefined,
        countryOfResidenceId: this.countryOfResidenceId || undefined,
        userType: this.userType || undefined,
        status: this.status || undefined,

        ...config,
      }
    },

    fetchInvites(query) {
      return this.waitRequest(() => {
        return this.fetchUserInvites(query)
          .then(() => (this.tableKey = Math.random()))
          .catch(this.checkErrorsAlert)
      })
    },

    create() {
      this.$router.push({ path: '/users/create' })
    },

    search() {
      const query = this.createQueryConfig()

      return this.fetchInvites(query)
    },

    country(id) {
      return this.countries.find(item => item.id === id)?.name || ''
    },

    approve(row) {
      this.updateUserStatus({ id: row.waitingList.id, status: 'approve' })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User approved',
              icon: 'BellIcon',
            },
          })
        })
        .catch(this.checkErrors)
    },

    decline(row) {
      this.updateUserStatus({ id: row.waitingList.id, status: 'decline' })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User declined',
              icon: 'BellIcon',
            },
          })
        })
        .catch(this.checkErrors)
    },

    isHandled(row) {
      const avaliableStatus = ['approved', 'declined']

      return avaliableStatus.includes(row.waitingList?.status)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
@import '~@core/scss/vue/libs/vue-good-table.scss';

.comments {
  width: 200px;
}
</style>
