export const columns = [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: 'User',
    field: 'fullName',
  },
  {
    label: 'Current Location',
    field: 'countryId',
  },
  {
    label: 'Country of Residence ',
    field: 'countryOfResidenceId',
  },
  {
    label: 'Email',
    field: 'user.email',
  },
  {
    label: 'User type',
    field: 'userType',
  },
  {
    label: 'Comment',
    field: 'comment',
  },
  {
    label: 'Action',
    field: 'action',
  },
]
