export const userTypes = {
  investor: 'investor',
  beta_user: 'beta_user',
  media_kol: 'media_kol',
  job_seeker: 'job_seeker',
  government_regulator: 'government_regulator',
  potential_commercial_partner: 'potential_commercial_partner',
  other: 'other',
}

export const statusTypes = {
  pending: 'pending',
  approved: 'approved',
  declined: 'declined',
}
